import { SearchOutlined } from "@ant-design/icons";
import { timestampToLocalTime } from "anka-endeavour";
import { Form, Input, Select, Space } from "antd";
import Beneficiary from "../components/Beneficiary";
import DataTable from "../components/DataTable";
import Status from "../components/Status";
import { hostname, useEndeavour } from "../hooks/useEndeavour";

function BeneficiariesPage() {
  const endeavour = useEndeavour();
  return (
    <DataTable
      uri={`${hostname}/ep/resources/beneficiaries`}
      filters={[
        <Form.Item label="Status" name="status">
          <Select
            options={[
              { label: "Pending", value: "pending" },
              { label: "Active", value: "active" },
            ]}
          />
        </Form.Item>,
        <Form.Item label="Name" name="name">
          <Input autoFocus />
        </Form.Item>,
        <Form.Item label="Bank Account Name" name="bank_account_name">
          <Input />
        </Form.Item>,
        <Form.Item label="Bank Account Number" name="bank_account_number">
          <Input />
        </Form.Item>,
        <Form.Item label="Bank SWIFT Code" name="bank_swift_code">
          <Input />
        </Form.Item>,
      ]}
      tableSize="small"
      tableColumns={[
        {
          title: "Time",
          align: "center",
          render: (data) => {
            return timestampToLocalTime(data.created_at);
          },
        },
        {
          title: "Status",
          align: "center",
          render: (data) => {
            return <Status value={data.status} />;
          },
        },
        {
          title: "Name",
          align: "center",
          render: (data) => {
            return data.name;
          },
        },
        {
          title: "Bank Account Name",
          align: "center",
          render: (data) => {
            return data.bank_account_name;
          },
        },
        {
          title: "Bank Account Number",
          align: "center",
          render: (data) => {
            return data.bank_account_number;
          },
        },
        {
          title: "Bank SWIFT Code",
          align: "center",
          render: (data) => {
            return data.bank_swift_code;
          },
        },
        {
          render: (data) => {
            return (
              <Space>
                <a
                  onClick={() => {
                    endeavour.openDrawer({
                      width: 768,
                      children: <Beneficiary entityID={data.entity_id} beneficiaryID={data.id} />,
                    });
                  }}
                >
                  <SearchOutlined />
                </a>
              </Space>
            );
          },
        },
      ]}
    />
  );
}

export default BeneficiariesPage;
