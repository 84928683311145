import { useQuery } from "@tanstack/react-query";
import { hostname, useEndeavour } from "../hooks/useEndeavour";
import { Col, Descriptions, Row, Skeleton, Button, Space } from "antd";
import Status from "./Status";
import { timestamp, timestampToLocalTime } from "anka-endeavour";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";

function Beneficiary({ entityID, beneficiaryID }) {
  const endeavour = useEndeavour();
  const query = useQuery({
    queryKey: ["entities", entityID, "beneficiaries", beneficiaryID],
    queryFn: () => {
      return fetch(`${hostname}/ep/resources/entities/${entityID}/beneficiaries/${beneficiaryID}`)
        .then((res) => res.json())
        .then((res) => {
          return res.payload;
        });
    },
  });

  if (query.isFetching) {
    return <Skeleton active />;
  }

  return (
    <Row gutter={[endeavour.token.padding * 2, endeavour.token.padding * 2]}>
      <Col span={24}>
        <Space>
          <Button>Whitelist WC1</Button>
          <Button icon={<CheckCircleOutlined />}>Approve</Button>
          <Button icon={<CloseCircleOutlined />}>Reject</Button>
        </Space>
      </Col>
      <Col span={24}>
        <Descriptions bordered column={2}>
          <Descriptions.Item label="UUID" span={2}>
            {query.data.uuid}
          </Descriptions.Item>
          <Descriptions.Item label="Status" span={2}>
            <Status value={query.data.status} />
          </Descriptions.Item>
          <Descriptions.Item label="Name" span={2}>
            {query.data.name}
          </Descriptions.Item>
          <Descriptions.Item label="Last Name">{query.data.last_name}</Descriptions.Item>
          <Descriptions.Item label="First Name">{query.data.first_name}</Descriptions.Item>
          <Descriptions.Item label="ID Type">{query.data.id_type.toUpperCase()}</Descriptions.Item>
          <Descriptions.Item label="ID Number">{query.data.id_number}</Descriptions.Item>
          <Descriptions.Item label="Bank Account Name" span={2}>
            {query.data.bank_account_name}
          </Descriptions.Item>
          <Descriptions.Item label="Bank Account Number">{query.data.bank_account_number}</Descriptions.Item>
          <Descriptions.Item label="Bank SWIFT Code">{query.data.bank_swift_code}</Descriptions.Item>
          <Descriptions.Item label="Approved By">{query.data.approved_by}</Descriptions.Item>
          <Descriptions.Item label="Approved at">{query.data.approved_at}</Descriptions.Item>
          <Descriptions.Item label="Created at">{timestampToLocalTime(query.data.created_at)}</Descriptions.Item>
          <Descriptions.Item label="Updated at">{timestampToLocalTime(query.data.updated_at)}</Descriptions.Item>
        </Descriptions>
      </Col>
    </Row>
  );
}

export default Beneficiary;
