import { Statistic } from "antd";

const ColoredStatistic = ({ title, value, precision = 2, prefix, suffix, props }) => {
  if (value === null || isNaN(value)) {
    return;
  }
  let color = "#ea3943";
  if (parseFloat(value) > 0) {
    color = "darkgreen";
  }
  if (prefix) {
    prefix = `${prefix}`;
  }
  switch (prefix) {
    case "TRX":
    case "ETH":
      precision = 8;
      break;
    default:
      precision = 2;
  }
  return (
    <Statistic
      title={title}
      value={value}
      prefix={suffix}
      suffix={prefix}
      precision={precision}
      style={{ textAlign: "right" }}
      valueStyle={{ color: color, textAlign: "right" }}
      {...props}
    />
  );
};

export default ColoredStatistic;
