import { Drawer, Modal, theme } from "antd";
import { createContext, useContext, useState } from "react";

const EndeavourContext = createContext();

// const hostname = "https://endeavour.paymentasia.com";
const hostname = "https://endeavour.uat.pa-g.com";
// const hostname = "http://localhost";

const EndeavourProvider = ({ children }) => {
  const [actionSheetProps, setActionSheetProps] = useState({
    open: false,
    placement: "bottom",
  });
  const [drawerProps, setDrawerProps] = useState({
    open: false,
  });
  const [modalProps, setModalProps] = useState({
    open: false,
  });
  const { token } = theme.useToken();

  const openActionSheet = (props) => {
    setActionSheetProps((c) => ({
      ...props,
      open: true,
      placement: "bottom",
    }));
  };
  const openDrawer = (props) => {
    setDrawerProps((c) => ({
      ...props,
      open: true,
    }));
  };
  const closeActionSheet = () => {
    setActionSheetProps({
      open: false,
      placement: "bottom",
    });
  };
  const closeDrawer = () => {
    setDrawerProps({
      open: false,
    });
  };
  const openModal = (props) => {
    setModalProps({
      ...props,
      open: true,
    });
  };
  const closeModal = () => {
    setModalProps({
      open: false,
    });
  };

  return (
    <EndeavourContext.Provider
      value={{
        openActionSheet,
        openDrawer,
        closeActionSheet,
        closeDrawer,
        openModal,
        closeModal,
        token,
      }}
    >
      {children}
      <Drawer {...drawerProps} onClose={closeDrawer} />
      <Modal {...modalProps} centered onCancel={closeModal} />
      <Drawer {...actionSheetProps} onClose={closeActionSheet} />
    </EndeavourContext.Provider>
  );
};

const useEndeavour = () => {
  const endeavour = useContext(EndeavourContext);
  return {
    ...endeavour,
  };
};

export { useEndeavour, EndeavourProvider, hostname };
