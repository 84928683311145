import { Form, Input, Space, Row, Col } from "antd";
import DataTable from "../components/DataTable";
import { hostname, useEndeavour } from "../hooks/useEndeavour";
import { timestampToLocalTime } from "anka-endeavour";
import Status from "../components/Status";
import { CheckCircleTwoTone, ExpandOutlined, SearchOutlined, CloseCircleTwoTone, CheckCircleOutlined, MinusSquareTwoTone } from "@ant-design/icons";
import User from "../components/User";
import { useNavigate } from "react-router-dom";

function UsersPage() {
  const endeavour = useEndeavour();
  const navigate = useNavigate();
  return (
    <DataTable
      tableSize="small"
      uri={`${hostname}/ep/resources/users`}
      filters={[
        <Form.Item label="Email" name="email">
          <Input autoFocus />
        </Form.Item>,
        <Form.Item label="Mobile" name="mobile">
          <Input />
        </Form.Item>,
      ]}
      tableColumns={[
        {
          title: "Time",
          align: "center",
          render: (data) => {
            return timestampToLocalTime(data.created_at);
          },
        },
        {
          title: "Email",
          align: "center",
          render: (data) => {
            return (
              <Space>
                {data.email}
                {data.verified_at && (
                  <CheckCircleOutlined
                    style={{
                      color: "blue",
                    }}
                  />
                )}
              </Space>
            );
          },
        },
        { title: "Mobile", align: "center", dataIndex: "mobile" },
        {
          title: "Status",
          align: "center",
          render: (data) => {
            return <Status value={data.status} />;
          },
        },
        {
          title: "Onboarding Info Status",
          align: "left",
          width: "20%",
          render: (data) => {
            return (
              <Row>
                {Object.entries(data.onboarding_status).map(([key, value]) => {
                  let icon;
                  let keyName;

                  switch (key) {
                    case "CardStatement":
                      keyName = "Card Statement";
                      break;
                    case "AddressProof":
                      keyName = "Address Proof";
                      break;
                    default:
                      keyName = key;
                  }

                  switch (value) {
                    case false:
                      icon = <CloseCircleTwoTone twoToneColor="#eb2f96" />;
                      break;
                    case "failed":
                      icon = <CloseCircleTwoTone twoToneColor="#eb2f96" />;
                      break;
                    case "inactive":
                      icon = <CloseCircleTwoTone twoToneColor="#eb2f96" />;
                      break;
                    case "0":
                      icon = <CloseCircleTwoTone twoToneColor="#eb2f96" />;
                      break;
                    case "active":
                      icon = <CheckCircleTwoTone twoToneColor="#52c41a" />;
                      break;
                    case "1":
                      icon = <CheckCircleTwoTone twoToneColor="#52c41a" />;
                      break;
                    case "success":
                      icon = <CheckCircleTwoTone twoToneColor="#52c41a" />;
                      break;
                    case "pending":
                      icon = <MinusSquareTwoTone twoToneColor="#BEBEBE" />;
                      break;
                    default:
                      icon = <MinusSquareTwoTone twoToneColor="#BEBEBE" />;
                      break;
                  }
                  return (
                    <>
                      <Col span={12} style={{ textAlign: "left" }}>
                        {keyName}
                      </Col>
                      <Col span={12} style={{ textAlign: "left" }}>
                        {icon}
                      </Col>
                    </>
                  );
                })}
              </Row>
            );
          },
        },
        {
          title: "Actions",
          render: (data) => {
            return (
              <Space>
                <a
                  onClick={() => {
                    endeavour.openDrawer({
                      title: data.email,
                      width: 1024,
                      children: <User userID={data.id} />,
                    });
                  }}
                >
                  <SearchOutlined />
                </a>
                <a
                  onClick={() => {
                    navigate(`/users/${data.id}`);
                  }}
                >
                  <ExpandOutlined />
                </a>
              </Space>
            );
          },
        },
      ]}
    />
  );
}

export default UsersPage;
