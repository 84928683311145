import { Skeleton, Alert } from "antd";
import { Column } from "@ant-design/plots";
import { hostname } from "../../hooks/useEndeavour";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";

export default function Histogram({ name, api, query, x, y, type, label, data }) {
  const [config, setConfig] = useState({
    data: [],
    isGroup: true,
    xField: x,
    yField: y,
    seriesField: type,
    label,
  });

  const queryHistogram = useQuery({
    queryKey: [name],
    enabled: !data,
    queryFn: async () => {
      const response = await fetch(`${hostname}${api}${query}`);

      const res = await response.json();
      return res.payload;
    },
    onSuccess: (data) => {
      // Update config with fetched data
      setConfig((prevConfig) => ({
        ...prevConfig,
        data: data || [],
      }));
    },
  });

  useEffect(() => {
    if (data !== undefined) {
      setConfig((prevConfig) => ({
        ...prevConfig,
        data: data,
      }));
    }
  }, [data]);

  // Render loading state
  if (queryHistogram.isLoading) {
    return <Skeleton active />;
  }

  // Render error state
  if (queryHistogram.isError) {
    return <Alert message="Error loading data" type="error" />;
  }

  // Render the Histogram
  return <Column {...config} />;
}
