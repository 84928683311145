import { Card, Col, Row, Skeleton, theme } from "antd";
import ColoredStatistic from "../components/ColoredStatistic";
import { useQuery } from "@tanstack/react-query";
import { hostname } from "../hooks/useEndeavour";
import Histogram from "../components/chart/Histogram";

const styleCardMetric = {
  textAlign: "right",
};
const formatter = (value) => <CountUp end={value} separator="," />;
function DashboardPage() {
  const { token } = theme.useToken();

  const queryUser = useQuery({
    queryKey: ["user"],
    queryFn: () => {
      return fetch(`${hostname}/ep/resources/dashboard-user`)
        .then((res) => res.json())
        .then((res) => {
          return res.payload;
        });
    },
  });

  const queryDeposits = useQuery({
    queryKey: ["queryDeposits"],
    queryFn: () => {
      return fetch(`${hostname}/ep/resources/dashboard-deposits`)
        .then((res) => res.json())
        .then((res) => {
          return res.payload;
        });
    },
  });

  const queryRemittance = useQuery({
    queryKey: ["queryRemittance"],
    queryFn: () => {
      return fetch(`${hostname}/ep/resources/dashboard-remittance`)
        .then((res) => res.json())
        .then((res) => {
          return res.payload;
        });
    },
  });

  if (
    queryUser.isFetching ||
    queryDeposits.isFetching ||
    queryRemittance.isFetching
  ) {
    return <Skeleton />;
  }

  return (
    <>
      <Row gutter={[token.padding * 2, token.padding * 2]}>
        <Col span={24}>
          <Card>
            <Card.Grid
              style={{ ...styleCardMetric, width: "25%" }}
              hoverable={false}
            >
              <ColoredStatistic
                title="Total Users"
                value={queryUser.data?.total || 0}
              />
            </Card.Grid>

            {queryDeposits.data?.length > 0 ? (
              queryDeposits.data.map((element) => (
                <Card.Grid
                  key={element.currency}
                  style={{ ...styleCardMetric, width: "25%" }}
                  hoverable={false}
                >
                  <ColoredStatistic
                    title={"Total Deposits"}
                    value={element.total_amount}
                    suffix={element.currency}
                    precision={2}
                    formatter={formatter}
                  />
                </Card.Grid>
              ))
            ) : (
              <Card.Grid
                style={{ ...styleCardMetric, width: "25%" }}
                hoverable={false}
              >
                <ColoredStatistic
                  title={"Total Deposits"}
                  value={0}
                  suffix={"HKD"}
                  precision={2}
                  formatter={formatter}
                />
              </Card.Grid>
            )}

            {queryRemittance.data?.length > 0 ? (
              queryRemittance?.data.map((element) => (
                <Card.Grid
                  key={element.currency}
                  style={{ ...styleCardMetric, width: "25%" }}
                  hoverable={false}
                >
                  <ColoredStatistic
                    title={"Total Remittance"}
                    value={element.total_amount}
                    suffix={element.currency}
                    precision={2}
                    formatter={formatter}
                  />
                </Card.Grid>
              ))
            ) : (
              <Card.Grid
                style={{ ...styleCardMetric, width: "25%" }}
                hoverable={false}
              >
                <ColoredStatistic
                  title={"Total Remittance"}
                  value={0}
                  suffix={"HKD"}
                  precision={2}
                  formatter={formatter}
                />
              </Card.Grid>
            )}

            <Card.Grid
              style={{ ...styleCardMetric, width: "25%" }}
              hoverable={false}
            >
              <ColoredStatistic
                title=" Profit"
                value={0}
                precision={2}
                formatter={formatter}
              />
            </Card.Grid>
          </Card>
        </Col>
        <Col span={24}>
          <Card title={"User Summary"}>
            <Histogram
              data={queryUser.data?.userSummary}
              x={"date"}
              y={"count"}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default DashboardPage;
